var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
exports = {
  name: "icon-opera",
  body: function iconOpera() {
    if ((this || _global).childElementCount) return;
    (this || _global).innerHTML = "<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" preserveAspectRatio=\"xMidYMid meet\" width=\"100%\" height=\"100%\" viewBox=\"0 0 13 15\" class=\"icon\">" + "<g>" + "<path d=\"M6.510,14.996 C5.617,14.996 4.784,14.819 4.016,14.461 C3.248,14.102 2.558,13.571 1.946,12.875 C1.277,12.128 0.782,11.318 0.467,10.446 C0.152,9.575 -0.001,8.596 -0.001,7.510 C-0.001,6.424 0.152,5.445 0.467,4.574 C0.782,3.703 1.277,2.892 1.946,2.145 C2.564,1.443 3.256,0.907 4.025,0.544 C4.794,0.184 5.624,0.004 6.510,0.004 C7.396,0.004 8.227,0.184 8.994,0.544 C9.763,0.907 10.456,1.443 11.073,2.145 C11.736,2.886 12.226,3.692 12.538,4.560 C12.850,5.426 13.001,6.411 13.001,7.510 C13.001,8.608 12.850,9.594 12.538,10.459 C12.226,11.328 11.736,12.133 11.074,12.875 C10.461,13.571 9.773,14.102 9.004,14.461 C8.235,14.819 7.403,14.996 6.510,14.996 C6.510,14.996 6.510,14.996 6.510,14.996 ZM6.510,14.028 C7.513,14.028 8.152,13.639 8.512,12.885 C8.898,12.081 9.116,10.317 9.116,7.510 C9.116,4.703 8.898,2.939 8.512,2.133 C8.152,1.381 7.513,0.992 6.510,0.992 C5.501,0.992 4.857,1.384 4.492,2.144 C4.103,2.956 3.884,4.716 3.884,7.510 C3.884,10.304 4.103,12.064 4.492,12.877 C4.857,13.635 5.501,14.028 6.510,14.028 C6.510,14.028 6.510,14.028 6.510,14.028 Z\" style=\"fill:inherit\" fill-rule=\"evenodd\"></path>" + "</g>" + "</svg>";
  }
};
export default exports;